// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import '@/assets/css/reset.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/element-variables.scss'
import store from '@/store/index.js'
import '@/assets/icons'
import '@/assets/js/svg.js'
import '@/assets/css/index.scss'
import '@/assets/css/fonts/iconfont.css'
import '@/components/index.js'

import TIM from 'tim-js-sdk'
import * as echarts from 'echarts';//引入echarts
import 'babel-polyfill'
import myStore  from '@/components/store'
import flvjs from 'flv.js'


Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。
Vue.prototype.TIM = TIM
Vue.prototype.flvjs = flvjs
Vue.prototype.$echarts = echarts
Vue.prototype.webUrl = process.env.VUE_APP_PUBLIC_BASE_URL
Vue.use(ElementUI, {size: 'small'})
Vue.prototype.$myStore = myStore;
Vue.prototype.msgSuccess = function(msg) {
    if (document.getElementsByClassName('el-message').length === 0) {
        this.$message({ showClose: true, message: msg, type: "success" })
    }
}

Vue.prototype.msgError = function(msg) {
    if (document.getElementsByClassName('el-message').length === 0) {
        this.$message({ showClose: true, message: msg, type: "error" })
    }
}

Vue.prototype.msgWarn = function(msg) {
    if (document.getElementsByClassName('el-message').length === 0) {
        this.$message({ showClose: true, message: msg, type: "warning" })
    }
}

Vue.prototype.msgInfo = function(msg) {
    if (document.getElementsByClassName('el-message').length === 0) {
        this.$message.info(msg)
    }
}

Vue.config.productionTip = false

/* eslint-disable no-new */
window.app = new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
})


let reloadFlag = false
window.addEventListener('error', handleListenerError, true);
function handleListenerError (eventErr){
  if (eventErr.srcElement.localName == 'link' || eventErr.srcElement.localName == 'script') {
        if(reloadFlag){
            return
        }
        reloadFlag = true
        alert('因版本更新，页面需重新载入!');
        window.location.reload();
  }
  eventErr.preventDefault()
}